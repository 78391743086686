//font-sizes
$h1-font-size: 0.9375em;
$h2-font-size: 1.0rem;
$h3-font-size: 1.70rem;
$h4-font-size: 1.875rem;
$h5-font-size: 1.25rem;
$h6-font-size: 3.125rem;
$h7-font-size: 1.125rem;
$h8-font-size: 0.813rem;
//color code
$c-cyan-azure: #357ddd;
$c-primary-mandatory-field: #800000;
$c-primary-mandatory-field1: #056008;
$c-secondary-fields: white;
$c-button-fields: #357ddd;
$c-primary-delete: red;
$c-green: green;
$c-purple: rgb(132, 25, 182);
$c-secondary-cyan-azure: #0f6ce6;
$c-gray: #80808038;
$c-dark-blue: #091E42;
$c-dark-taupe: #534F4F;
$c-black: #000000;
$c-translucent-gray: #f2f2f2;
$c-blue: blue;
$c-gainsboro: #cccccc;
$c-dark-gray: #666;
$c-light-blue: #007bff;
$c-primary-gray: gray;
$c-whitesmoke: #f0f0f0;
$c-pure-white: #ffffff;
$c-light-gray: #f3f7ff;
$c-smoke-white: #fdfdfd;
$c-orange:#f59638;
$c-grayish:#8F8D8D;
$c-black: #000000;


//Mobile
//color code
$app-background: rgb(0, 196, 204);
$app-background-gradient: linear-gradient(to right, #5de0e6, #004aad);
$white: #ffffff;
$input-text-color: rgba(0, 0, 0, 0.6);
$header-color: #004aad;