@import '/src/scss/base';
@import '/src/scss/custom-material';
@import "node_modules/ngx-toastr/toastr.css";

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0 !important;
    height: 100vh;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {

    //common styles
    .tab-body button {
        background: $header-color !important;
        color: $white !important;
    }

    //landing page common styles
    .landing-page-container {
        background: rgb(0, 196, 204);
        background-image: linear-gradient(to right, #5de0e6, #004aad);

        .logo-container img {
            width: 100%;
            margin: -2rem 0 !important;
        }

        .login-container {
            border-radius: 20px;
            background: white;
            height: 510px;
        }
    }

    //login page common styles
    .login-container {
        padding: 1rem 0;

        .login-card {
            margin: 3rem;
            text-align: center;

            form {
                display: flex;
                flex-direction: column;

                a {
                    padding: 0 1rem 1rem;
                    text-decoration: none;
                }
            }

            .login-btn {
                background: $app-background-gradient;
                color: $white !important;
            }
        }
    }

    //dashboard common styles
    .mat-mdc-tab-header {
        position: relative !important;
        bottom: 0;
        width: 100%;
    }

    .mat-mdc-tab-body-content {
        height: 93vh !important;
    }

    //tabs common styles
    .tab-container {
        .tab-header {
            display: flex;
            justify-content: space-between;
            background: $header-color;
            color: #ffffff;
            height: 65px;

            h4 {
                padding: 0 2rem;
            }

            .mat-icon {
                transform: scale(1.2);
                margin: 1rem;
            }
        }
    }


}